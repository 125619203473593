export abstract class Regex {
    // - MATCHERS -
    private static numbersMatcher(repetition?: string) {
        return `\\d${repetition || ''}`;
    }
    private static decimalNumbersMatcher(max_number: number) {
        return `\\d+([,\\.]\\d{1,${max_number}})?`;
    }
    private static readonly LETTER_ONLY_MATCHER = "a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'";
    private static readonly POD_MATCHER = 'it\\d{3}e\\d{7,8}[a\\d]?';
    private static readonly PDR_MATCHER = Regex.numbersMatcher('{14}');
    private static readonly CF_MATCHER =
        '(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]';
    private static readonly PIVA_MATCHER = Regex.numbersMatcher('{11}');
    private static readonly ADDRESS_STREET_MATCHER = "[A-Z][A-Z'°àèùéìò]+";
    private static readonly ADDRESS_CIVIC_MATCHER = '(\\d+[a-i]?|SNC)';
    private static readonly ADDRESS_CITY_MATCHER = "[A-Z][A-Z'àèùéìò]+";

    // - REGEX -
    // Common
    public static readonly LETTER_ONLY = new RegExp(`^[${Regex.LETTER_ONLY_MATCHER}]*$`, 'i');
    public static readonly NUMBER_ONLY = new RegExp(`^${Regex.numbersMatcher('*')}$`);
    public static readonly LETTER_NUMBERS = new RegExp(
        `^[${Regex.LETTER_ONLY_MATCHER}${Regex.numbersMatcher()}]*$`,
        'i',
    );
    public static readonly LETTER_AND_SPACE = new RegExp(`^[${Regex.LETTER_ONLY_MATCHER}\\s]*$`, 'i');
    public static readonly LETTER_NUMBERS_SPACE = new RegExp(
        `^[${Regex.LETTER_ONLY_MATCHER}${Regex.numbersMatcher()}\\s]*$`,
        'i',
    );
    private static readonly PUNCTUATION_MATCHER = '\\.,;:!\\?\\-_\\(\\)\\[\\]\\{\\}"\'';
    public static readonly LETTER_NUMBER_SPACE_PUNCTUATION = new RegExp(
        `^[${Regex.LETTER_ONLY_MATCHER}${Regex.numbersMatcher()}\\s${Regex.PUNCTUATION_MATCHER}]*$`,
        'i',
    );

    public static readonly NO_LEADING_OR_TRAILING_SPACES = new RegExp(`^[\\S]+(.*\\S)?$`, 'i');
    public static readonly TWO_DECIMAL_NUMBERS = new RegExp(`^${Regex.decimalNumbersMatcher(2)}$`);
    public static readonly SIX_DECIMAL_NUMBERS = new RegExp(`^${Regex.decimalNumbersMatcher(6)}$`);
    public static readonly NUMBER_GREATER_THAN_ZERO = /^(?:[1-9]\d*)?$/;
    public static readonly ITALIAN_DATE = /^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/;
    public static readonly NUMBER_DECIMAL_POINT_ONE_OR_HIGHER = /^[1-9]\d*(\.\d{2})?$/;
    public static readonly NUMBER_DECIMAL_POINT_ZERO_OR_HIGHER = /^[1-9]?\d+(\.\d{2})?$/;

    // Client Data
    public static readonly CF = new RegExp(`^${Regex.CF_MATCHER}$`, 'i');
    public static readonly PIVA = new RegExp(`^${Regex.PIVA_MATCHER}$`);
    public static readonly CF_OR_PIVA = new RegExp(`(?:^${Regex.CF_MATCHER}$)|(?:^${Regex.PIVA_MATCHER}$)`, 'i');
    public static readonly CELL = new RegExp(`^(?:3)${Regex.numbersMatcher('+')}$`);
    public static readonly ISTAT = /^.{6}$/;
    public static readonly EMAIL = /^([a-zA-Z\d_\-\.]+)@([a-zA-Z\d_\-\.]+)\.([a-zA-Z]{2,5})$/;
    public static readonly IBAN = /^(?:IT|SM)\d{2}[A-Z]\d{10}[A-Z\d]{12}$/i;
    public static readonly ATECO_CODE = /^\d{2}\.\d{2}\.\d{2}$/;
    public static readonly POD = new RegExp(`^${Regex.POD_MATCHER}$`, 'i');
    public static readonly PDR = new RegExp(`^${Regex.PDR_MATCHER}$`);
    public static readonly PDF = new RegExp(`^${Regex.numbersMatcher('{10}')}$`);
    public static readonly POD_OR_PDR = new RegExp(`(?:^${Regex.POD_MATCHER}$)|(?:^${Regex.PDR_MATCHER}$)`, 'i');

    // Addresses
    public static readonly CAP = new RegExp(`^${Regex.numbersMatcher('{5}')}$`);
    public static readonly STREET_CIVIC_CITY = new RegExp(
        `^(${Regex.ADDRESS_STREET_MATCHER}(?:(?:\\s+|-)${Regex.ADDRESS_STREET_MATCHER})*)\\s*(?:(?:,|\\s)\\s*${Regex.ADDRESS_CIVIC_MATCHER})?(?:\\s*(?:,|\\s)\\s*(${Regex.ADDRESS_CITY_MATCHER}(?:(?:\\s+|-)${Regex.ADDRESS_CITY_MATCHER})*))?\\s*$`,
        'i',
    );
    /**
     * @description Verifica che il CAP sia tra quelli soppressi
     */
    public static readonly EXPIRED_CAP =
        /^(?:(?:(?:\d0|0[69]|1[569]|2[459]|3[4578]|4[1-478]|5[67]|6[15]|7[14]|8[49]|9[58])1|(?:28|47)9)00|47023)$/;
    public static readonly STREET_EGON_CODE = new RegExp(`^${Regex.numbersMatcher('{11}')}$`);

    // Routes
    public static readonly DOMICILIATION_INIT_ROUTE = /^domiciliation(?:\/([a-zA-Z\d_\-\.]+)){3}/;
    public static readonly CATALOG_ROUTE =
        /(?:^|\/)(?:(?:allproducts|carts|cp\/(?:productdetails|productconfig|active))|tv\/params)(?:$|[\/?])/;
    public static readonly ORDER_ENTRY_ROUTE = /\/order-entry\//;
    public static readonly ORDER_ENTRY_PRODUCT_CONFIG_ROUTE = /(?:^|\/)order-entry\/productconfig(?:$|[\/?])/;

    // Id matchers
    public static readonly D365_GUID_MATCHER = /^[\da-f]{8}(?:-[\da-f]{4}){4}[\da-f]{8}$/i;
    public static readonly SALESFORCE_ID_MATCHER = /^[\da-z]{18}$/i;
    public static readonly L10N_TOKEN_MATCHER =
        /^(?:\d*[A-Z]+\d*)+(?:_[A-Z\d]+)*(?:\.(?:\d*[A-Z]+\d*)+(?:_[A-Z\d]+)*)*$/;
    public static readonly QUOTE_NAME = new RegExp(`^Q-${Regex.numbersMatcher('+')}$`, 'i');
    public static readonly ORDER_NAME = new RegExp(`^O-${Regex.numbersMatcher('+')}$`, 'i');

    // Misc
    public static readonly COD_DESTINATARIO = /^[A-Za-z\d]{7}$/;
    public static readonly ISO_8601 =
        /^(?:[12]\d{3}(?:-(?:(?:0[1-9]|1[0-2])|(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d)|(?:0[469]|11)-30|(?:0[13578]|1[02])-3[01])(?:T(?:[01]\d|2[0-3])(?::[0-5]\d(?::[0-5]\d(\.\d{3})?)?(?:Z|[-+](?:[01]\d|2[0-3])(?::?[0-5]\d)?)?)?)?)))$/;
    public static readonly TELEPHONE_SPLITTER =
        /^((?:(?:\+|00)(?:[17]|(?:2[07]|3[0-469]|4[013-9]|5[1-8]|6[0-6]|8[1246]|9[0-58])|(?:2[1-68-9]|3[578]|42|5[09]|6[7-9]|8[58]|9[679])\d))){0,2}[\s-]*(\d+[\d\s-]+\d+)$/;
    public static readonly FLAG_FIELD_NAME_MATCHER = /^is[A-Z]/;
    public static readonly BULK_ORDER_JSON_MATCHER = /^(?:[^:]+:\s*)(.+)$/;
    public static readonly COMBINED_SALES_PRODUCT_GROUP_MATCHER = /vendita\s*abbinata/i;
    public static readonly PDF_FILE_EXTENSION = /.pdf$/i;
}
