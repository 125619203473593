export interface TransferModelResponse {
    Score: string;
    EsitoSemaforico: TrafficLightOutcome;
}

export enum TrafficLightOutcome {
    greenLight = 'Semaforo Verde',
    yellowLight = 'Semaforo Giallo',
    redLight = 'Semaforo Rosso',
    unknown = 'Non Disponibile',
    error = 'error',
}
