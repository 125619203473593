import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';
import { MD5 } from 'crypto-js';
import _, { isEmpty as _isEmpty, omit, set } from 'lodash';
import * as moment from 'moment';
import { Observable, OperatorFunction } from 'rxjs';
import { isEmpty, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
    ProductAddressType,
    ProductAddressTypesVisibility,
} from '../../modules/common/models/product-address-visibility.types';
import { PRODUCT_FAMILY_ADDRESS_MAP } from '../../modules/common/summary/configurations/address-visibility.config';
import { Product } from '../../store/models/order-entry-state_v2';
import { Regex } from '../config/regex';
import { AptAddressType } from '../enums/apttus/apt-address-type';
import { AptCommodityType } from '../enums/apttus/apt-commodity-typeof-sale';
import { AptLineStatus } from '../enums/apttus/apt-line-status';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { DestinationUse } from '../enums/shared/destination-use.enum';
import { SubProductType } from '../enums/shared/sub-product-type';
import { DeepPartial } from '../interfaces/deep-partial';
import { EglCartItemExtended } from '../models/apttus/tables/cart/egl-cart-item-extended';
import { containsProductComplex, containsProductMaintenance, isCommodityFamily } from './verifications.functions';
/**
 * Transform multiline string into single line string
 * @param string input string
 * @returns single line string
 */
export const singleLineString = (string: string): string => {
    // Split on newlines.
    const lines = string.split(/(?:\r\n|\n|\r)/);

    // Rip out the leading whitespace.
    return lines
        .map((line) => line.replace(/^\s+/gm, ''))
        .join(' ')
        .trim();
};

/**
 * Return true/false if the input string is a valid JSON
 * @param str
 * @returns boolean
 */
export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const jsonTryParse = <T>(str: string, defaultValue: T = null) => {
    try {
        return JSON.parse(str) as T;
    } catch (e) {
        return defaultValue;
    }
};

/**
 * @description Dati due codici fiscali restituisce 100 se sono identici, altrimenti un numero più basso da 0 a 100
 * @param oldCf Vecchio codice fiscale
 * @param newCf Nuovo codice fiscale
 * @returns number Percentuale di somiglianza dei due codici fiscali
 */
export const calculateTaxCodeAffinity = (oldCf: string, newCf: string): number => {
    if ((oldCf || '').length < 16 || (newCf || '').length < 16) {
        return -1;
    }
    let lettersCollisions = 0;
    for (let i = 0; i < oldCf.length; i++) {
        if (oldCf[i] === newCf[i]) {
            lettersCollisions++;
        }
    }
    return Math.round((lettersCollisions * 100) / 16);
};

/**
 * Generic toLocalDateString. Return a formatted string based on Locale format
 * @param date
 * @returns string in locale format
 */

export const toLocaleDateString = (date: Date | string): string => {
    if (date instanceof Date) {
        return date.toLocaleDateString();
    }
    if (typeof date === 'string') {
        return new Date(date).toLocaleDateString();
    }
    return;
};

export const formatDateTime = (stringDate: string): string => {
    if (!stringDate) return;
    return moment(stringDate).isValid() ? moment(stringDate).format('DD/MM/yyyy - HH:mm') : '';
};

export const formatDate = (stringDate: string): string => {
    return moment(stringDate).isValid() ? moment(stringDate).format('DD/MM/yyyy') : '';
};

export const toLocaleDateTimeString = (date: Date | string): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return `${toLocaleDateString(date)} - ${date.toLocaleTimeString()}`;
};

export const writeImportantInfoLog = (message: string, ...optionalParams: any[]) => {
    console.info(`%c${message}`, 'background: pink; color: blue; font-size: x-large', ...optionalParams);
};

export const writeImportantErrorLog = (message: string, ...optionalParams: any[]) => {
    console.error(`%c${message}`, 'background: red; color: yellow; font-size: x-large', ...optionalParams);
};

export const activationCartFromUrl = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return (urlParams.get('acart') || '').split('?v')[0];
};

/**
 * @description: Restituisce un guid univoco
 * @return string
 */
export const getTransactionId = (): string => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const newGuid = (): string => {
    return uuidv4();
};

export const getRandomHash = (): string => {
    return `#${Math.floor(Math.random() * 32000)}`;
};

export const parsePrice = (value: string | number): number => {
    const parsedValue = typeof value === 'number' ? value : parseFloat(value);
    return isNaN(parsedValue) || value === null || typeof value === 'undefined' ? null : parsedValue;
};

export const parseToDate = (value: unknown, format?: moment.MomentFormatSpecification): Date | null => {
    if (!value) return null;

    if (typeof value === 'string' && value.length >= 10 && moment(value, format).isValid()) {
        return moment(value, format).toDate();
    }
    if (moment.isMoment(value) && value.isValid()) {
        return value.toDate();
    }
    if (value instanceof Date && !!value.getTime()) {
        return value;
    }
    return null;
};

export function parseToISO8601DateOnly(value: any): string {
    const dt = parseToDate(value);

    return dt ? moment(dt).format('YYYY-MM-DD') : null;
}

export const parseToDateOrValue = <T>(value: T, format?: moment.MomentFormatSpecification): T | Date => {
    return parseToDate(value, format) || value;
};

export const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};
/**
 * clean object deep clean, delete null, empty undefined properties, arrays, objects
 * @param obj
 * @param config
 * @author Ricupero ft Lombardi
 * @returns <object>
 */
export const cleanObj = <T>(
    obj: T,
    config: { delete?: boolean; keepEmptyObjects?: boolean; slimDownArray?: boolean; keepEmptyStrings?: boolean } = {},
): DeepPartial<T> => {
    config.delete = config.delete !== false; //Default: true
    config.keepEmptyObjects = config.keepEmptyObjects === true; //Default: false
    config.slimDownArray = config.slimDownArray !== false; //Default: true
    config.keepEmptyStrings = config.keepEmptyStrings === true; //Default: false
    if (typeof obj === 'object') {
        for (let p in obj as any) {
            if (
                typeof obj[p] === 'undefined' ||
                obj[p] === null ||
                (typeof obj[p] === 'string' && !(obj[p] as unknown as string).trim() && !config.keepEmptyStrings)
            ) {
                if (config.delete) {
                    delete obj[p];
                } else {
                    obj[p] = undefined;
                }
            } else if (typeof obj[p] === 'object' && !(obj[p] instanceof Date)) {
                (obj as unknown)[p] = cleanObj(obj[p]);
                if (
                    config.delete &&
                    !config.keepEmptyObjects &&
                    ((Array.isArray(obj[p]) && !(obj[p] as unknown as any[]).length) ||
                        !Object.keys(obj[p] || {}).length)
                ) {
                    delete obj[p];
                }
            }
        }
        if (Array.isArray(obj) && config.slimDownArray) {
            for (let i = obj.length - 1; i >= 0; i--) {
                if (typeof obj[i] === 'undefined') {
                    obj.splice(i, 1);
                }
            }
        }
    }
    return obj as DeepPartial<T>;
};

/**
 * @author Ricupero ft. Fadda
 * @description in memory of Mario Brega
 * Lorenzo: Uso il forEach invece del reduce perchè ho bisogno che venga restituito il "vecchio" oggetto in input invece di crearne uno nuovo.
 * All'interno delle logiche del SDK vengono controllate il tipo delle instanze e quindi creando un nuovo oggetto plain, non funzionerebbe
 */
export const apttusResponseIronHand = <T>(inputValue: T, skipProps: string[] = []): T => {
    if (typeof inputValue === 'object' && !!inputValue) {
        Object.entries(inputValue).forEach(([key, value]) => {
            const newKey = key.replace(/(^Apttus_Config2__)|(__c$)|(Id__r$)|(_$)/g, '');
            inputValue[newKey] =
                skipProps.indexOf(newKey) === -1
                    ? Array.isArray(value)
                        ? value.map((arrayValue) => apttusResponseIronHand(arrayValue, skipProps))
                        : apttusResponseIronHand(value, skipProps)
                    : value;
            if (key !== newKey) {
                delete inputValue[key];
            }
        });
    }
    return inputValue;
};

/**
 * @author Ricupero ft. Natalia & Jacopo
 * @description in memory of Mario Brega 🝷
 * Rimuovi tutti i campi tecnici dalle risponse di D365 ricostruendo una struttura annidiata
 */
export const d365ResponseSugarHam = <R>(d365Response: { [key: string]: unknown }): R =>
    Object.entries(d365Response).reduce(
        (aggr, [key, value]) => (key.includes('@') ? aggr : set(aggr, key, value)),
        {},
    ) as R;

type AptProductForFiltering = Partial<{
    LineStatus: AptLineStatus;
    egl_cartdetails_visibility: boolean;
    ProductType: AptProductType;
}>;
type AptLineItemForFiltering = DeepPartial<
    AptProductForFiltering & {
        productType: AptProductType;
        Option: AptProductForFiltering;
        Product: AptProductForFiltering;
    } & Product
>;

/**
 * @description: Filtra i cart o quote item rimuovendo quelli associati ad elementi tecnici
 * @param cartItems: L'array dei cart o quote items da filtrare
 * @return: Array di cart o quote items filtrati
 */
export const isNotTechAssetItem = (item: any): boolean =>
    ((typedItem) =>
        ![AptProductType.FornituraGas, AptProductType.FornituraLuce].includes(
            typedItem?.Product?.ProductType ||
                typedItem?.Option?.ProductType ||
                typedItem?.productType ||
                typedItem?.ProductType,
        ))(item as AptLineItemForFiltering);

export const isVisibleProduct = (item: any): boolean => {
    const typedItem = item as AptLineItemForFiltering;
    return ![
        typedItem?.Option?.egl_cartdetails_visibility,
        typedItem?.Product?.egl_cartdetails_visibility,
        typedItem?.egl_cartdetails_visibility,
        typedItem?.isVisible,
    ].includes(false);
};
export const isVisibleNotTechProduct = (item: any): boolean => {
    const typedItem = item as AptLineItemForFiltering;
    return isVisibleProduct(typedItem) && isNotTechAssetItem(typedItem);
};

export const isActivePackageProduct = (item: any): boolean => {
    const typedItem = item as AptLineItemForFiltering;
    return (
        ![
            typedItem?.Option?.LineStatus,
            typedItem?.LineStatus,
            typedItem?.Product?.LineStatus,
            typedItem?.lineItemStatus,
        ].includes(AptLineStatus.Cancelled) && isVisibleNotTechProduct(item)
    );
};

export const isActiveNotTechProduct = (item: any): boolean => {
    const typedItem = item as AptLineItemForFiltering;
    return (
        ![
            typedItem?.Option?.LineStatus,
            typedItem?.LineStatus,
            typedItem?.Product?.LineStatus,
            typedItem?.lineItemStatus,
        ].includes(AptLineStatus.Cancelled) && isNotTechAssetItem(item)
    );
};

/**
 * @description: Filtra i cart item rimuovendo quelli associati ad elementi di sconto standalone
 * @param cartItems: L'array dei cart items da filtrare
 * @return: Array di cart items filtrati
 */
export const isNotDiscountItem = (item: any) => !isDiscountItem(item);

export const isDiscountItem = (item: any): boolean => {
    const discount = [
        AptProductType.ScontoStandAloneGas,
        AptProductType.ScontoStandAloneLuce,
        AptProductType.ScontoStandAloneExtracommodity,
    ];
    return (
        [item?.Product?.ProductType, item?.Option?.ProductType, item?.productType, item?.ProductType].filter((type) =>
            discount.includes(type),
        ).length > 0
    );
};

export const compareFunOrderAsc = (valueA: string, valueB: string) => {
    const stringA = valueA.toUpperCase();
    const stringB = valueB.toUpperCase();
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }
    return 0;
};
export const mergeEmpty =
    <T, R>(onEmptyFn: () => Observable<R> | Promise<R>): OperatorFunction<T, R | T> =>
    (source) =>
        source.pipe(
            isEmpty(),
            mergeMap((isSourceObsEmpty) => (isSourceObsEmpty ? onEmptyFn() : source)),
        );

/**
 * @description: Verify if the input value is a valid number and not NaN
 * @param value
 * @return: true / false if the input value is a valid number
 */
export const isValidNumber = (value: any): value is number => typeof value === 'number' && !isNaN(value);

export const keyifyObj = (obj: object, prefix = ''): string[] =>
    Object.keys(obj).reduce((res, el) => {
        if (Array.isArray(obj[el])) {
            return res;
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
            return [...res, ...keyifyObj(obj[el], prefix + el + '.')];
        }
        return [...res, prefix + el];
    }, []);

export const omitObjAFromObjB = <T extends object>(objA: object, objB: T): Partial<T> => {
    if (objA && objB) {
        const keysToRemove = keyifyObj(cleanObj(objA));
        return omit(objB, keysToRemove);
    }
    return objB;
};

export function flagToBool(value: string | boolean): boolean {
    return !!flagToBoolOrNull(value);
}

export function flagToBoolOrNull(value: string | boolean): boolean {
    if (typeof value === 'boolean') {
        return value;
    }
    if (typeof value !== 'string') {
        return null;
    }
    return /no?|false/i.test(value) ? false : /^(s[iì]?|y(?:es)?|true)$/i.test(value) || null;
}

//logiche per la somma dei prezzi
//ATTENZIONE: non è una semplice somma numerica, null = N/A
export function sumPrices(
    price1: number | null | undefined,
    ...prices: (number | null | undefined)[]
): number | null | undefined {
    return prices.reduce((aggr, price) => {
        // if ([aggr, price].every((val) => typeof val === 'undefined')) {
        //     return undefined;
        // }
        if ([aggr, price].some((val) => val === null)) {
            return null;
        }
        return (aggr || 0) + (price || 0);
    }, price1);
}

enum ValueDiffResult {
    Added = 'Added Value',
    Missing = 'Missing Value',
    DifferentValue = 'Different Value',
    DifferentType = 'Different Type',
}

interface IObjDiffResult {
    [key: string]: DiffResult;
}

type DiffResult = ValueDiffResult | IObjDiffResult | DiffResult[];

export function valueDiff(src: any, dst: any): DiffResult {
    // Controllo la coerenza dei tipi
    if (
        (isNullOrUndefined(src) === isNullOrUndefined(dst) &&
            typeof src === typeof dst &&
            Array.isArray(src) === Array.isArray(dst) &&
            src instanceof Date === dst instanceof Date) ||
        (isNullOrUndefined(src) && !isNullOrUndefined(dst) && typeof dst === 'object' && !(dst instanceof Date)) ||
        (!isNullOrUndefined(src) && isNullOrUndefined(dst) && typeof src === 'object' && !(src instanceof Date))
    ) {
        // Tipi coerenti

        if ((isNullOrUndefined(src) && isNullOrUndefined(dst)) || src === dst) {
            // Verifica OK, non aggiungo alla lista
            return null;
        } else if (Array.isArray(src) || Array.isArray(dst)) {
            // Gestisco l'array
            return arrayDiff(src || [], dst || []);
        } else if (src instanceof Date) {
            // Confronto le date con margine 1 giorno
            return moment(src).diff(moment(dst), 'days') ? ValueDiffResult.DifferentValue : null;
        } else if (typeof src === 'object' || typeof dst === 'object') {
            // Gestisco l'oggetto
            return objDiff(src || {}, dst || {});
        } else {
            // Valore differente
            return ValueDiffResult.DifferentValue;
        }
    } else if (!isNullOrUndefined(src) && !isNullOrUndefined(dst)) {
        // Campo valorizzato su entrambi gli oggetti - Tipo differente
        return ValueDiffResult.DifferentType;
    } else if (!isNullOrUndefined(src) && src !== false && isNullOrUndefined(dst)) {
        // Campo valorizzato solo nel sorgente - Campo mancante
        return ValueDiffResult.Missing;
    } else if (isNullOrUndefined(src) && !isNullOrUndefined(dst) && dst !== false) {
        // Campo valorizzato solo nella destinazione - Campo in eccesso
        return ValueDiffResult.Added;
    }
    // i due campi sono emntrambi null/undefined/false
    return null;
}

function arrayDiff(src: Array<any>, dst: Array<any>): DiffResult[] {
    // Verifico le differenze tra i due array
    // Verifico per ogni elemento dell'array se c'è una controparte nella destinazione
    let checkedList = src
        .map((sElement, idx) => (dst.length > idx ? valueDiff(sElement, dst[idx]) : ValueDiffResult.Missing))
        .concat(
            // Verifico eventuali elementi in eccesso nella lista di destinazione
            dst.length > src.length
                ? Array.from(new Array(dst.length - src.length)).map(() => ValueDiffResult.Added)
                : [],
        );

    // Rimuovo i risultati null che sono degli esiti positivi
    checkedList = checkedList.filter(Boolean);

    return checkedList.length ? checkedList : null;
}

function objDiff(src: object, dst: object): IObjDiffResult {
    // Usare la valueDiff che effettua prima dei controlli di coerenza sul tipo

    const resp = {};
    const keys = new Set<string>([...Object.keys(src), ...Object.keys(dst)]);

    keys.forEach((key) => {
        const result = valueDiff(src[key], dst[key]);
        if (result) {
            resp[key] = result;
        }
    });

    return Object.keys(resp).length ? resp : null;
}

export function isLocalHost() {
    return location.href.indexOf('localhost') !== -1;
}

export function parsePhoneNumber(
    completePhoneNumber: string,
    defaultPrefix: string = '+39',
): { prefix: string; phone: string } {
    const [, prefix, phone] = completePhoneNumber?.match(Regex.TELEPHONE_SPLITTER) || [];
    return {
        prefix: (prefix || (phone && defaultPrefix) || '').replace('00', '+') || null,
        phone: phone || null,
    };
}

/**
 * @description Restituisce il prodotto per il quale deve essere mostrata la domanda dell'indirizzo di residenza
 */
export function findProductToShowResidentialAddress({
    products,
    cartSegment,
}: {
    products: Product[];
    cartSegment: D365CustomerSegment;
}): Product | null {
    return (
        products.find((product) => productsToAdressTypesVisibility([product], cartSegment)?.RESIDENZA === true) ||
        products.find((product) => productsToAdressTypesVisibility([product], cartSegment)?.RESIDENZA) ||
        null
    );
}

export function showResidentialAddress({
    product,
    products,
    cartSegment,
}: {
    product?: Product;
    products: Product[];
    cartSegment: D365CustomerSegment;
}): boolean {
    const targetProduct = findProductToShowResidentialAddress({ products, cartSegment });
    return !product ? !!targetProduct : product === targetProduct;
}

/**
 * @return Returns defaultvalue if value is empty, else value.
 */
export function getStringOrDefault(value: string, defaultvalue: string = '-'): string {
    return value || defaultvalue;
}

function productToProductFamilyAddress(product: Product): ProductAddressType {
    if (product?.subProductType === SubProductType.RCDanni) {
        return 'INSURANCE_RC';
    }
    if (isCommodityFamily(product?.family)) {
        if (
            product?.powerOrGas === AptCommodityType.Power &&
            product?.configurations?.destinationUse === DestinationUse.Appurtenances
        ) {
            return 'POWER_APPURTENANCES';
        }

        return product?.powerOrGas;
    }
    return product?.family;
}
// S
export function productsToAdressTypesVisibility(
    products: Product[],
    segment: D365CustomerSegment,
): ProductAddressTypesVisibility {
    return products
        .map((product) => ({
            ...(PRODUCT_FAMILY_ADDRESS_MAP.DEFAULT || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[productToProductFamilyAddress(product)] || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[product.productType] || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[product.subProductType] || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[segment].DEFAULT || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[segment][productToProductFamilyAddress(product)] || {}),
            ...(PRODUCT_FAMILY_ADDRESS_MAP[segment][product.productType] || {}),
        }))
        .reduce(
            (aggr, value) => ({
                FORNITURA: aggr.FORNITURA || value.FORNITURA,
                SPEDIZIONE: aggr.SPEDIZIONE || value.SPEDIZIONE,
                RESIDENZA: [aggr.RESIDENZA, value.RESIDENZA].some((residenza) => residenza === true)
                    ? true
                    : [aggr.RESIDENZA, value.RESIDENZA].some((residenza) => residenza === 'questionOnly')
                      ? 'questionOnly'
                      : false,
                FATTURAZIONE: aggr.FATTURAZIONE || value.FATTURAZIONE,
                SEDE_LEGALE: aggr.SEDE_LEGALE || aggr.SEDE_LEGALE,
                DOMICILIO: aggr.DOMICILIO || value.DOMICILIO,
                CONSOCIATA: aggr.CONSOCIATA || value.CONSOCIATA,
                ABITAZIONE_ABITUALE: aggr.ABITAZIONE_ABITUALE || value.ABITAZIONE_ABITUALE,
                ABITAZIONE_DI_PROPRIETA: aggr.ABITAZIONE_DI_PROPRIETA || value.ABITAZIONE_DI_PROPRIETA,
            }),
            {
                FORNITURA: false,
                SPEDIZIONE: false,
                RESIDENZA: false,
                FATTURAZIONE: false,
                SEDE_LEGALE: false,
                DOMICILIO: false,
                CONSOCIATA: false,
                ABITAZIONE_ABITUALE: false,
                ABITAZIONE_DI_PROPRIETA: false,
            } as ProductAddressTypesVisibility,
        ) as ProductAddressTypesVisibility;
}

export function productToAptAdressType(product: Product, segment: D365CustomerSegment): AptAddressType {
    const { FORNITURA, SPEDIZIONE } = productsToAdressTypesVisibility([product], segment);
    return FORNITURA ? AptAddressType.Fornitura : SPEDIZIONE ? AptAddressType.Spedizione : null;
}

export function isDbarMode() {
    // 3d0m$0n4T|T4n0$m0d3
    return MD5((localStorage['dbar'] || '') + 'sdeDo0pGjD465Mq4ncls').toString() === '8ff376eb00c11b357fd1eef5c120aaa3';
}

/**
 * If Properies is null or undefined replace the value with ''
 * @param obj
 */
export function valueOrStringEmpty<T>(obj: T): T {
    if (_isEmpty(obj)) return obj;
    return Object.keys(obj).reduce((agg, currkey) => {
        const currVal = obj[currkey];
        return {
            ...agg,
            ...{ [currkey]: _isEmpty(currVal) ? '' : currVal },
        };
    }, {} as T);
}

/**
 * Return a Key of object finding a given value into his array
 * @param obj Object like { foo: [1, 2, 4], goo: [5, 7], ...}
 * @param findValue One element of array ex: 1, 6, ...
 * @param defaultValue default value if findValue non found. Default is null.
 * @returns
 */
export function getKeyFromObjArrayValues<T, G>(
    obj: { [key in string]?: T[] },
    findValue: T,
    defaultValue: G = null,
): string | G {
    const key = Object.entries(obj || {}).find(([_, valueArray]) => valueArray.includes(findValue))?.[0];
    return key || defaultValue;
}

export function getAvailablePower(instantaneousPower: number): number {
    return !instantaneousPower ? null : Math.ceil(instantaneousPower * 11) / 10;
}

export function toTitleCase(string: string): string {
    if (!string || string?.length === 0) return string;
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function removeSFIDs<T extends object>(obj: T): T {
    if (!obj) return obj;

    let newObj = { ...obj };
    for (let key in newObj) {
        if (SFDC_DEFAULT_FIELDS.includes(key)) {
            delete newObj[key];
        }
    }
    return newObj;
}

export const SFDC_DEFAULT_FIELDS = [
    'Id',
    'IsDeleted',
    'CreatedDate',
    'LastModifiedDate',
    'Name',
    'attributes',
    'Apttus_Config2__LineItemId__c',
    'CreatedById',
    'LastModifiedById',
    'SystemModstamp',
];

export function EnumContainsFlag(e: number, flag: number): boolean {
    if (!e || !flag) {
        return false;
    }

    if (isNaN(e) || isNaN(flag)) {
        return false;
    }

    return flag === (flag & e);
}
export function checkFreeProduct(cartItems: EglCartItemExtended[]): boolean {
    return cartItems?.some(
        (item: EglCartItemExtended) =>
            item?.egl_ItemGeneratedByRule == item?.Id && item?.Product?.ConfigurationType == 'Bundle',
    );
}
/**
 * Check if the product needs appointment
 * @param allVisibleProducts Product[]
 * @returns boolean
 */
export function _checkAppointment(allVisibleProducts: Product[]): boolean {
    return allVisibleProducts?.some((p) => checkAppointment(p)) && allVisibleProducts.length === 1;
}

export function checkAppointment(Product: Product): boolean {
    return (
        (containsProductComplex(Product.productType) &&
            Product?.technicalDetails?.characteristics?.some((c) => c.Name === 'Sopralluogo standard') &&
            _.isEmpty(Product?.configurations?.relatedOffer)) ||
        (containsProductMaintenance(Product.productType) && _.isEmpty(Product?.configurations?.relatedOffer))
    );
}

export function sortBricksStatePrds(products: Product[]): Product[] {
    const hasDifferentSequences = products
        .map((p) => p.brickList)[0]
        ?.some((brick, index, array) =>
            array.some((otherBrick, otherIndex) => otherIndex !== index && otherBrick.sequence !== brick.sequence),
        );
    // Ordina l'array basandosi sulla condizione trovata
    products
        .map((p) => p.brickList)[0]
        .sort((a, b) => {
            if (hasDifferentSequences) {
                return a.sequence - b.sequence; // Ordina per sequence
            } else {
                return a.brickName.localeCompare(b.brickName); // Ordina per brickName
            }
        });
    return products;
}

/**
 * Calcola la data di Pasqua per l'anno corrente utilizzando l'algoritmo di Meeus/Jones/Butcher.
 * @returns La data di Pasqua come oggetto Date.
				   
 */
export function calculateEaster(): Date {
    const year = new Date().getFullYear();
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31);
    const day = ((h + l - 7 * m + 114) % 31) + 1;
    const easterDate = new Date(year, month - 1, day);
    console.log(`La Pasqua nel ${year} è il ${easterDate.toLocaleDateString()} 🝰`);
    return easterDate;
}

/**
 * Calcola la data di Pasquetta (il giorno dopo Pasqua) utilizzando la funzione calculateEaster().
 * @returns La data di Pasquetta come oggetto Date.
 */
export function calculatePasquetta(): Date {
    const pasquetta = moment(calculateEaster()).add(1, 'days').toDate();
    return pasquetta;
}
