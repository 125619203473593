import { Injectable } from '@angular/core';
import { ApiService, MetadataService } from '@congacommerce/core';
import { CartItem, CartRequest, CartService, LineItemService, ProductAttributeValue } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { isEmpty as _isEmpty } from 'lodash';
import { combineLatest, from, Observable, of } from 'rxjs';
import { concatMap, filter, isEmpty, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { CartPricingService } from '../../../../modules/common/order-entry/services/cart-pricing.service';
import { setV2ProductLineItemId, setV2Products } from '../../../../store/actions/order-entry-v2.actions';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { DatiAnagraficiMBV2, Product } from '../../../../store/models/order-entry-state_v2';
import { EglState } from '../../../../store/reducers';
import {
    v2SelectAllProducts,
    v2SelectAnagraficaMb,
    v2SelectCommoditiesByFilter,
    v2SelectExtracommoditiesByFilter,
    v2SelectFlowType,
    v2SelectMacroFlowType,
    v2SelectVisibleProducts,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { AptCommodityType } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { AptLineStatus } from '../../../enums/apttus/apt-line-status';
import { AptLineType } from '../../../enums/apttus/apt-line-type';
import { AptProductFamily } from '../../../enums/apttus/apt-product-family';
import { AptProductType } from '../../../enums/apttus/apt-product-type';
import { removeSFIDs } from '../../../functions/misc.functions';
import { getProductCommodityType } from '../../../functions/remap.functions';
import { EglItemGroup } from '../../../functions/transformation.functions';
import { containsProductSmartHome, isCommodityFamily } from '../../../functions/verifications.functions';
import { AnyApttusProduct } from '../../../models/apttus/cart.types';
import { EglCartItemExtended } from '../../../models/apttus/tables/cart/egl-cart-item-extended';
import { EglCartItemLight } from '../../../models/apttus/tables/cart/egl-cart-item-light';
import { LoadingService } from '../../shared/loading.service';
import { LoggerService } from '../../shared/logger.service';
import { PrivateConfigurationService } from '../../shared/private-configuration.service';

const SKIP_RULE_PARAM = 'rules=skip';
type LineItemGroupType<I extends CartItem | EglCartItemLight | EglCartItemExtended> = EglItemGroup<I> & { Flat: I[] };

@Injectable({ providedIn: 'root' })
export class EglAddProductToCartService {
    constructor(
        private store: Store<EglState>,
        private cartSrv: CartService,
        private cartPricingService: CartPricingService,
        private apiService: ApiService,
        private metadataService: MetadataService,
        private logger: LoggerService,
        protected configService: PrivateConfigurationService,
    ) {}

    /**
     * @description MacroFlowType products filtering rules
     */
    private readonly SELECTOR_MAP: {
        [key in MacroFlowType]?: (commodityType?: AptCommodityType) => Observable<Product[]>;
    } = {
        [MacroFlowType.CambioProdotto]: (commodityType) =>
            this.store.select(v2SelectCommoditiesByFilter(commodityType, { assetId: true, lineItemdId: false })),
        [MacroFlowType.Voltura]: (commodityType) =>
            this.store.select(v2SelectCommoditiesByFilter(commodityType, { sourceAssetId: true, lineItemdId: false })),
        [MacroFlowType.SwitchIn]: (commodityType) =>
            combineLatest([
                this.store.select(v2SelectCommoditiesByFilter(commodityType, { lineItemdId: false })),
                this.store.select(v2SelectExtracommoditiesByFilter({ lineItemdId: false })),
            ]).pipe(map(([commodities, extraCommodities]) => [...commodities, ...extraCommodities])),
        [MacroFlowType.Extracommodity]: () =>
            this.store.select(v2SelectExtracommoditiesByFilter({ lineItemdId: false })),
    };

    /**
     * @description Select products from state according to MacroFlowType filtering rules
     */
    private macroFlowTypeAndCommodityTypeProductSelector(
        macroFlowtype: MacroFlowType,
        product?: AnyApttusProduct,
    ): Observable<Product[]> {
        return this.SELECTOR_MAP[macroFlowtype]
            ? this.SELECTOR_MAP[macroFlowtype](getProductCommodityType(product)).pipe(take(1))
            : of([]);
    }

    /**
     * Clone and cean PAV obj from related cart product to new product
     * @param products
     * @returns return object that contain the request to addItems
     */
    private prepareAddRequest(
        products: Product[],
    ): Observable<{ skipRule: boolean; product: Product; request: CartRequest }[]> {
        return this.cartSrv
            .getMyCart()
            .pipe(
                take(1),
                map((cart) => cart.LineItems),
                LoadingService.loaderOperator('Aggiunta prodotto'),
            )
            .pipe(
                // costruisco una mappa productId/pav per poterli aggiungere alla request di aggiunta di nuovi prodotti al carrello
                map((curLineItems) =>
                    LineItemService.groupItems(curLineItems).reduce(
                        (aggr, lineItemGrp) => {
                            const mainLine = lineItemGrp?.MainLine as EglCartItemExtended;
                            const productId = mainLine?.ProductId;
                            return {
                                ...aggr,
                                [productId]: aggr[productId] || mainLine?.AttributeValue,
                            };
                        },
                        {} as { [productId: string]: ProductAttributeValue },
                    ),
                ),
                // Preparo la request per l'aggiunta al carrello, mantenendo la lista dei lineItem correnti per poter calcolare i delta necessarrio all'associazine dei lineItemId
                map((productIdAttributeMap) => {
                    return products.map((product) => ({
                        // #203437 - Skippo sempre le rules e poi scateno la "validate" che ricontrolla i lineitems nel cart e aggiunge i prodotti tecnici mancanti (tramite rules sui prodotti)
                        skipRule: true,
                        product,
                        request: {
                            ProductId: product.productId,
                            Quantity: 1,
                            ProductAttributes: removeSFIDs(productIdAttributeMap[product.productId]),
                        },
                    }));
                }),
            );
    }

    /**
     * @description Add the given product to the cart and returns the new added lineItems
     */
    private addItemToCartAndDispatch(
        newProduct: Product,
        request: CartRequest,
        skipRules = false,
    ): Observable<Product & Required<Pick<Product, 'lineItemId'>>> {
        return this.addItemNoPrice([request], skipRules)
            .pipe(
                tap((newLineItems) => {
                    if (!Array.isArray(newLineItems) || !newLineItems?.length) {
                        throw Error('No newLineItems added');
                    }
                }),
            )
            .pipe(
                map((addedLines) => {
                    // Raggruppo i lineItems
                    const groupedLineItems = LineItemService.groupItems(addedLines);
                    const lineItemId = (<LineItemGroupType<EglCartItemExtended>[]>groupedLineItems).find(
                        (groups) => groups.MainLine?.ProductId === request?.ProductId,
                    )?.MainLine?.Id;

                    return { ...newProduct, lineItemId };
                }),
                // Aggiorno il prodotto nello state
                tap(({ idx: productIdx, lineItemId }) =>
                    this.store.dispatch(setV2ProductLineItemId({ productIdx, lineItemId })),
                ),
            );
    }

    /**
     * @description
     */
    private addMultipleProductsToCart(targetProduct: AnyApttusProduct): Observable<void> {
        return this.store.select(v2SelectAllProducts).pipe(
            take(1),
            tap((products) => this.dispatchMultiQuantityProduct(products)),
            mergeMap(() =>
                // Recupero il macro flowtype per determinare l'opportuna strategia di recupero prodotti dallo state
                this.store
                    .select(v2SelectMacroFlowType)
                    .pipe(
                        take(1),
                        // Recupero i prodotti dallo state in base alla strategia associata al macroFlowType corrente
                        mergeMap((macroFlowType) =>
                            this.macroFlowTypeAndCommodityTypeProductSelector(macroFlowType, targetProduct).pipe(
                                map((products) => ({
                                    macroFlowType,
                                    products,
                                })),
                            ),
                        ),
                        // In assenza di prodotti privi di lineItemId non emetto valori (EMPTY Observable), riprenderà alla toArray()
                        filter(({ products }) => !!products?.length),
                    )
                    .pipe(
                        mergeMap(({ products }) => this.prepareAddRequest(products)),
                        tap((addItemReqList) => {
                            this.logger.info(
                                `[ADD_PRD_SRV] Products added in multisupply page: ${addItemReqList?.length || 0}`,
                                addItemReqList.map(({ product }) => product?.name),
                            );
                        }),
                        // emetto un evento per ogni prodotto aggiornato
                        mergeMap((addItemReqList) => from(addItemReqList)),
                        // Richiamo la addToCart con i parametri elaborati per il prodotto e per il carrello
                        concatMap(({ request, skipRule, product }) =>
                            this.addItemToCartAndDispatch(product, request, skipRule),
                        ),
                    )
                    .pipe(
                        toArray(),
                        // Modifico i prodotti che hanno quantità maggiore di 1 e la riporto a 1.
                        // Questo perchè i prodotti saranno stati splittati
                        // Ad esempio un lineitem con quantity 3 verrà trasformato in 3 lineitem con quantity 1.
                        // Innesco la rivalidazione del carrello per rieseguire le rules lato SF e forzare l'aggiunta dei prodotti tecnici.
                        // Successivamente rieseguo il pricing (TODO ottimizzazione: controllare se è possibile ottimizzare la chiamata al pricing)
                        mergeMap(() =>
                            this.normalizeProductQuantity().pipe(
                                mergeMap(() =>
                                    combineLatest([
                                        this.store.select(v2SelectAnagraficaMb),
                                        this.store.select(v2SelectFlowType),
                                        this.store.select(v2SelectVisibleProducts('ALL')),
                                    ]).pipe(
                                        take(1),
                                        mergeMap(([anagraficaMb, flowType, visibleProducts]) => {
                                            if (!this.shouldRevalidateCart(anagraficaMb, flowType, visibleProducts)) {
                                                return of(null);
                                            }

                                            return this.revalidateCart().pipe(map(() => null));
                                        }),
                                    ),
                                ),
                                mergeMap(() =>
                                    this.cartPricingService.priceCartWithRetry(
                                        this.configService.get('cartToQuotePricingMode', undefined),
                                    ),
                                ),
                            ),
                        ),
                        map(() => null),
                    ),
            ),
        );
    }

    private dispatchMultiQuantityProduct(products: Product[]): void {
        let maxId = Math.max(...products.map((p) => p.idx));
        const multiQuantitySplittedProducts = products.reduce(
            (accumulator, currentProduct) => {
                for (let i = 0; i < (currentProduct.configurations?.quantity || 1); i++) {
                    const isExistingProduct = !accumulator.some(
                        (product) => product.lineItemId === currentProduct.lineItemId,
                    );
                    accumulator = [
                        ...accumulator,
                        {
                            ...currentProduct,
                            lineItemId: isExistingProduct ? currentProduct.lineItemId : undefined,
                            configurations: {
                                ...currentProduct.configurations,
                                quantity: 1,
                            },
                            idx: isExistingProduct ? currentProduct.idx : ++maxId,
                        },
                    ];
                }
                return accumulator;
            },
            <Product[]>[],
        );
        this.store.dispatch(setV2Products({ products: multiQuantitySplittedProducts }));
    }

    public normalizeProductQuantity() {
        return this.cartSrv
            .getMyCart()
            .pipe(
                take(1),
                map((cart) => cart.LineItems.filter((li) => li.Quantity > 1)),
                LoadingService.loaderOperator('Normalizzazione quantità prodotti'),
            )
            .pipe(
                filter((lineItemsToUpdate) => !!lineItemsToUpdate?.length),
                map((lineItemsToUpdate) =>
                    lineItemsToUpdate.map((li) => ({
                        LineItem: {
                            ...li.toApiJsonFromExpose(),
                            Apttus_Config2__Quantity__c: 1,
                            Apttus_Config2__PricingStatus__c: 'Pending',
                        },
                        ProductAttributes: li.AttributeValue,
                    })),
                ),
                mergeMap((lineItemsToUpdate) => from(lineItemsToUpdate)),
                concatMap((lineItem) => this.updateItem(lineItem)),
                toArray(),
                isEmpty(),
            );
    }

    private getCartProducts(): Observable<AnyApttusProduct> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map(
                (cart) =>
                    LineItemService.groupItems(cart.LineItems) as unknown as LineItemGroupType<EglCartItemExtended>[],
            ),
            mergeMap((itemGroups) => from(itemGroups)),
            // Filtro i primary line product/service con status non Cancelled
            filter(
                (itemGroup) =>
                    itemGroup.MainLine.IsPrimaryLine &&
                    itemGroup.MainLine.LineType === AptLineType.ProductService &&
                    itemGroup.MainLine.LineStatus !== AptLineStatus.Cancelled &&
                    // verificare se utilizzare isCommodityFamily(che include anche commodity legacy)
                    (isCommodityFamily(<AptProductFamily>itemGroup.MainLine.Product.Family) ||
                        containsProductSmartHome([<AptProductType>itemGroup.MainLine.Product.ProductType])),
            ),
            map((itemGroup) => itemGroup.MainLine.Product),
        );
    }

    /**
     * @description Entry point - Processo automatico per l'aggiunta al carrello di prodotti multifornitura se privi di lineItemId
     */
    public eglAddMultiProductToCart(targetProduct?: AnyApttusProduct): Observable<void> {
        // Posso ricevere in ingresso il prodotto da aggiungere N volte per tipo GAS/LUCE (Voltura, Cambio Prodotto)
        // Devo escludere i prodotti nello state già aggiunti nel carrello (con lineItemId)
        // In assenza di product in input, recupero 1 prodotto per tipo e lo replico sui product nello state privi di lineItemId
        return (targetProduct ? of(targetProduct) : this.getCartProducts()).pipe(
            concatMap((cartProduct) => this.addMultipleProductsToCart(cartProduct)),
            // In assenza di prodotti da aggiungere
            isEmpty(),
            LoadingService.loaderOperator(),
            map(() => null),
        );
    }

    /**
     * This is a copy of OOB CartService.addItem but it don't calls the price api.
     * @param cartRequestList
     * @param skipRules
     */
    private addItemNoPrice(cartRequestList: Array<CartRequest>, skipRules = false): Observable<Array<CartItem>> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            switchMap((cart) => (!cart.Id ? this.cartSrv.createNewCart() : of(cart))),
            map(({ Id: cartId }) => {
                const url = `/carts/${cartId}/items?${this.cartSrv['getQueryParams']()}&alias=false`;
                // nel caso in cui il parametro di skip rule sia non stato già aggiunto da this.cartSrv['getQueryParams'] lo aggiungo
                if (skipRules && !url.includes(SKIP_RULE_PARAM)) {
                    return [url, SKIP_RULE_PARAM].join('&');
                }
                return url;
            }),
            tap((apiUrl) => this.logger.info(`[ADD_PRD_SRV] Calling ${apiUrl}`, cartRequestList)),
            switchMap((apiUrl) => this.apiService.post(apiUrl, cartRequestList)),
            map((result) => {
                if (_isEmpty(result.LineItems)) {
                    throw new Error('Failed to add item(s)');
                } else {
                    this.logAddItemDetails(result);
                    return plainToClass(
                        this.metadataService.getTypeByApiName('Apttus_Config2__LineItem__c'),
                        result.LineItems,
                    ) as unknown as Array<CartItem>;
                }
            }),
            tap((cartItemList: CartItem[]) => {
                this.cartSrv['mergeCartItems'](cartItemList); // scatena un republish e quindi una getMyCart
            }),
        );
    }

    /**
     * This method log how many lines are added by addItems Conga api
     * @param resp Additem response
     */
    private logAddItemDetails(resp: AddItem.Response): void {
        const logParam = (resp.LineItems || []).reduce(
            (agg, curr, idx) => ({
                ...agg,
                [curr.Id || idx]: {
                    description: curr.Apttus_Config2__Description__c,
                    type: curr.Apttus_Config2__LineType__c,
                    name: curr.Name,
                    optionType: curr.Apttus_Config2__OptionId__r?.Apttus_Config2__ProductType__c,
                },
            }),
            {} as {
                [key in string]?: {
                    description: string;
                    type: string;
                    name: string;
                    optionType: string;
                };
            },
        );
        this.logger.info(`[ADD_PRD_SRV] Added ${resp.LineItems?.length || 0} line(s)`, logParam);
    }

    /**
     * Make a revalidation of the cart to force the execution of the rules Salesforce side
     */
    private revalidateCart(): Observable<void> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map(({ Id: cartId, LineItems }) => {
                const apiUrl = `/carts/${cartId}/items/validate`;
                const mainLineIds = (LineItems || [])
                    .filter((li) => li.LineType === 'Product/Service')
                    .filter((li) => li.LineStatus !== 'Cancelled')
                    .map((li) => li.Id);
                return { apiUrl, mainLineIds };
            }),
            tap(({ apiUrl, mainLineIds }) => this.logger.info(`[ADD_PRD_SRV] Calling ${apiUrl}`, mainLineIds)),
            switchMap(({ apiUrl, mainLineIds }) => this.apiService.post(apiUrl, mainLineIds)),
            tap((response) => {
                if (response?.hasErrors) {
                    this.logger.error(null, '[ADD_PRD_SRV] Error revalidating cart', response);
                    throw new Error('Si è verificato un errore durante la rivalidazione del carrello');
                }
            }),
        );
    }

    private updateItem(cartRequest: CartRequest): Observable<CartItem> {
        const lineItemId = (<CartItem>cartRequest.LineItem).Id;
        return this.apiService.put(
            `/carts/${CartService.getCurrentCartId()}/items/${lineItemId}?${this.cartSrv[
                'getQueryParams'
            ]()}&alias=false`,
            cartRequest,
        );
    }

    shouldRevalidateCart(anagraficaMb: DatiAnagraficiMBV2, flowType: FlowType, products: Product[]): boolean {
        const REVALIDATION_MAP: Partial<{ [key in FlowType | 'DEFAULT']: () => boolean }> = {
            [FlowType.Voltura]: () => {
                const areCartItemsEqual =
                    products.length > 1 ? new Set(products?.map((p) => p?.powerOrGas)).size === 1 : false;
                const skipVoltura = anagraficaMb?.piva && areCartItemsEqual;
                return !skipVoltura;
            },
            DEFAULT: () => true,
        };

        return (REVALIDATION_MAP[flowType] || REVALIDATION_MAP['DEFAULT'])();
    }
}

declare module AddItem {
    interface LineItem {
        Id: string;
        Name: string;
        Apttus_Config2__Description__c: string;
        Apttus_Config2__LineType__c: string;
        Apttus_Config2__OptionId__r: {
            Apttus_Config2__ProductType__c: string;
        };
    }
    interface Response {
        Rules: any[];
        ConfigurationId: string;
        LineItems: LineItem[];
    }
}
