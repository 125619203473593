import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { D365CustomerSegment } from '../../../../common/enums/d365/d365-customer-segment';
import { BaseApiResponse, StatusResponse } from '../../../../common/interfaces/base-api-response';
import { ServiceError } from '../../../../common/models/app/service-error';
import { ApiMngApi, BaseProvider } from '../../../../common/providers/base-provider';
import { ApiService } from '../../../../common/services/shared/api.service';
import { LoadingService } from '../../../../common/services/shared/loading.service';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { PrivateConfigurationService } from '../../../../common/services/shared/private-configuration.service';
import { setV2TransferModel } from '../../../../store/actions/order-entry-v2.actions';
import { FlowType } from '../../../../store/models/flow-type';
import { Product, ProductConfigurations } from '../../../../store/models/order-entry-state_v2';
import { EglState } from '../../../../store/reducers';
import {
    v2SelectCurrentProduct,
    v2SelectFlowType,
    v2SelectVisibleProducts,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { selectCurrentProfile, selectUserState } from '../../../../store/selectors/user.selectors';
import { Supply, TransferModelRequest } from '../models/transfer-model-request';
import { TrafficLightOutcome, TransferModelResponse } from '../models/transfer-model-response';

@Injectable({ providedIn: 'root' })
export class TransferModelService extends BaseProvider {
    constructor(
        protected store: Store<EglState>,
        protected configSrv: PrivateConfigurationService,
        translateSrv: TranslateService,
        private api: ApiService,
    ) {
        super(configSrv, translateSrv);
    }
    loggerService: LoggerService;

    /**
     * @description La chiamata "modello voltura" ha la funzione di intercettare l’intento fraudolento di eludere azioni di recupero credito senza procedere al saldo degli importi dovuti.
     * @returns Observable<TransferModelResponse>
     */
    public transferModel(): Observable<TransferModelResponse> {
        let req: TransferModelRequest;
        return combineLatest([
            this.store.select(selectUserState),
            this.store.select(v2SelectCurrentProduct()),
            this.store.select(selectCurrentProfile),
            this.store.select(v2SelectFlowType),
            this.store.select(v2SelectVisibleProducts()),
        ]).pipe(
            map(([user, product, profile, flowType, visibleProducts]) => {
                if (flowType !== FlowType.Voltura || product?.isMortisCausa) {
                    //Salto il controllo
                    return null;
                } else {
                    return (req = {
                        SegmentoVolturante: user?.cartSegment,
                        POD_PDR: user?.cartSegment === D365CustomerSegment.Residential ? product?.podPdr : '',
                        CodiceFiscale:
                            user?.cartSegment === D365CustomerSegment.Residential ? user?.contact?.egl_taxcode : '',
                        PartitaIva:
                            user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.egl_vatcode : '',
                        NomeCognome:
                            user?.cartSegment === D365CustomerSegment.Residential
                                ? `${user?.contact?.firstname} | ${user?.contact?.lastname} `
                                : '',
                        RagioneSociale:
                            user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.name : '',
                        companyName: '',
                        CodiceFiscaleLegaleRappresentante: '',
                        FormaGiuridica: '',
                        TipoControlloCreditizio: 'modello voltura',
                        CanaleAcquisizione: profile?.selectedVirtualAg?.VirtualAgency?.Channel?.Code,
                        CodiceAgenzia: profile?.selectedVirtualAg?.CurrentCode,
                        ModalitaPagamentoVolturante: product?.paymentInfo?.paymentInstrument,
                        MailVolturante:
                            user?.cartSegment === D365CustomerSegment.Residential ? user?.contact?.emailaddress1 : '',
                        PECVolturante:
                            user?.cartSegment === D365CustomerSegment.Microbusiness ? user?.contact?.emailaddress1 : '',
                        CellulareVolturante: user?.contact?.mobilephone,
                        ClienteVolturante: user?.customerMastership,
                        ModalitaOperativa: flowType,
                        Forniture: this.getSupplyInfo(visibleProducts),
                        FlagVulnerabilitaVolturante: this.getVulnerability(product?.configurations),
                        ResidenteNonResidente: product?.configurations?.supplyUse,
                        FasciaOraria: product?.configurations?.hoursBundle,
                    });
                }
            }),
            switchMap((req) => {
                if (req == null) return of(null);
                return this.api
                    .postAsync<
                        BaseApiResponse<TransferModelResponse>
                    >(this.getApiMngApiUrl(ApiMngApi.TransferModel), req)
                    .pipe(
                        map((response) => {
                            if (
                                (response?.result !== '001' || response?.status !== StatusResponse.Success) &&
                                response instanceof BaseApiResponse
                            ) {
                                throw new ServiceError(
                                    response?.errorManagement?.errorCode,
                                    response.errorManagement?.errorDescription,
                                );
                            }
                            return response?.response;
                        }),
                        map((res) => res as TransferModelResponse),
                        catchError((error: Error) => {
                            this.loggerService.error('Errore chiamata gestione case', error?.message, error, true);
                            this.store.dispatch(
                                setV2TransferModel({
                                    transferModel: { EsitoSemaforico: TrafficLightOutcome.error, Score: null },
                                }),
                            );
                            return of(null);
                        }),
                    );
            }),
            tap((res) => {
                if (res === null) return;
                this.store.dispatch(
                    setV2TransferModel({ transferModel: { EsitoSemaforico: res?.EsitoSemaforico, Score: res?.Score } }),
                );
            }),
            map(() => null),
            LoadingService.loaderOperator('Attendere...'),
        );
    }

    private getVulnerability(config: ProductConfigurations): string[] {
        const vulnerabilities: string[] = [];
        if (config.vulnerabilityOver75) vulnerabilities.push('OVER_75');
        if (config.vulnerabilitySocialBonus) vulnerabilities.push('SOCIAL_BONUS');
        if (config.vulnerabilitySaeMapre) vulnerabilities.push('SAE_MAPRE');
        if (config.vulnerabilityDisabled) vulnerabilities.push('DISABILITY');
        return vulnerabilities;
    }
    private getSupplyInfo(products: Product[]): Supply[] {
        return products.map(
            (p) =>
                ({
                    Commodity: p?.powerOrGas,
                    POD_PDR: p?.podPdr,
                    ProductType: p?.productType,
                    ProductFamily: p?.family,
                }) as Supply,
        );
    }
}
