import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SegoeMDL2Font } from '../../../enums/shared/segoe-mdl2-font';

@Component({
    selector: 'egl-modal-with-footer',
    templateUrl: './egl-modal-with-footer.component.html',
    styleUrls: ['./egl-modal-with-footer.component.scss'],
})
export class EglModalWithFooterComponent implements OnDestroy {
    containerClass: string;
    modalId: string;
    isOpen: boolean;
    message: string;
    @Input() title: string;
    @Input() description: string;
    @Input() secondMessage: string;
    @Input() buttonLabel: string;
    @Input() secondButtonLabel?: string;
    @Input() thirdButtonLabel?: string;
    @Input() closeOnConfirm: boolean;
    @Input() confirmDisabled: boolean;
    @Input() backDisabled?: boolean;
    @Input() hideConfirm = false;
    @Output() onClose = new EventEmitter<void>();
    @Output() onShow = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<void>();
    @Output() onDismiss = new EventEmitter<void>();
    @Output() genericEvent = new EventEmitter<void>();
    @Output() onBack = new EventEmitter<void>();
    @Input() path?: string;
    @Input() withCircle?: boolean;
    @Input() isBlue: boolean;
    @Input() hideDismissButton?: boolean;
    readonly icons = SegoeMDL2Font;
    selectedValue: any;

    constructor(private renderer: Renderer2) {
        this.isOpen = false;
    }
    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'modal-open');
    }

    show(): void {
        this.isOpen = true;
        this.renderer.addClass(document.body, 'modal-open');
        this.onShow.emit();
    }

    hide(): void {
        this.isOpen = false;
        this.renderer.removeClass(document.body, 'modal-open');
        this.onClose.emit();
    }

    confirm(): void {
        this.onConfirm.emit();
        if (this.closeOnConfirm) {
            this.hide();
        }
    }

    toggle(): void {
        if (this.isShown) {
            this.hide();
        } else {
            this.show();
        }
    }

    dismiss(): void {
        this.onDismiss.emit();
        this.hide();
    }

    genericAction(): void {
        this.genericEvent.emit();
    }

    back(): void {
        this.onBack.emit();
    }

    /**
     *
     */
    get isShown(): boolean {
        return this.isOpen;
    }

    public toObservable(params?: { message?: string }): Observable<'DISMISS' | 'BACK' | 'CONFIRM'> {
        return new Observable<'DISMISS' | 'BACK' | 'CONFIRM'>((subscriber) => {
            // imposto eventuale messaggio
            this.message = params?.message || null;
            // apro la modale
            this.show();
            race(
                this.onDismiss.pipe(map(() => 'DISMISS')),
                this.onBack.pipe(map(() => 'BACK')),
                this.onConfirm.pipe(map(() => 'CONFIRM')),
                this.onClose.pipe(map(() => 'DISMISS')),
            )
                .pipe(take(1))
                .subscribe((eventName: 'DISMISS' | 'BACK' | 'CONFIRM') => {
                    subscriber.next(eventName);
                    subscriber.complete();
                });
        });
    }
}
