export enum AptProductType {
    Accessorio = 'Accessorio',
    AssicurazioneAncillare = 'Assicurazione Ancillare',
    AssicurazioneIntermediata = 'Assicurazione Intermediata / RUI',
    Servizio = 'Servizio',
    TariffaCommodityGas = 'Tariffa Commodity Gas',
    ProdottoCommodityGas = 'Prodotto Commodity Gas',
    ProdottoAtomicoCommodityGas = 'Prodotto Atomico Commodity Gas',
    ProdottoAtomicoCommodityLuce = 'Prodotto Atomico Commodity Luce',
    TariffaCommodityLuce = 'Tariffa Commodity Luce',
    ProdottoCommodityLuce = 'Prodotto Commodity Luce',
    Manutenzione = 'Manutenzione',
    SmartHome = 'Smart Home',
    FornituraLuce = 'Fornitura Luce',
    FornituraGas = 'Fornitura Gas',
    Sconti = 'Sconti',
    ScontoStandAloneLuce = 'Sconto Stand Alone Luce',
    ScontoStandAloneGas = 'Sconto Stand Alone Gas',
    ScontoStandAloneExtracommodity = 'Sconto ExtraCommodity',
    Complex_caldaia = 'Caldaia',
    Complex_scaldacqua = 'Scaldacqua',
    Complex_condizionatori = 'Condizionatori',
    Complex_sist_ric_elettrica = 'Sistemi di ricarica elettrica',
    Complex_pompe_calore = 'Pompe di calore',
    Complex_comp_tecnica = 'Componentistica Tecnica',
    Pannello = 'Pannello',
    Sopralluogo = 'Sopralluogo',
}
