import { createAction, props } from '@ngrx/store';
import { AptBillType } from '../../common/enums/apttus/apt-bill-type';
import { SupplyUse } from '../../common/enums/shared/destination-use.enum';
import { TypeOfUse } from '../../common/enums/shared/type-of-usage.type';
import { DeepPartial } from '../../common/interfaces/deep-partial';
import { ProductPriceEntity } from '../../modules/cart-costs/services/product-costs.service';
import { FormDatiPagamento } from '../../modules/common/order-entry/models/form-dati-pagamento';
import { Ese } from '../../modules/common/order-entry/steps/appointment/models/ese';
import { Convention } from '../../modules/common/partnership/models/conventions';
import { TaxDeduction } from '../../modules/common/partnership/models/taxDeductions';
import { MotivoRevoca } from '../../modules/domiciliation/init-domiciliation/init-domiciliation.component';
import { TransferModelResponse } from '../../modules/transfer/transfer-model/models/transfer-model-response';
import { FatturazioneElettronica } from '../models/order-entry-state';
import {
    Address,
    BusinessDetails,
    DatiAnagraficiMBV2,
    ExtracommodityBooking,
    GasTechnicalDetails,
    OrderEntryState_v2,
    PowerTechnicalDetails,
    Product,
    ProductTaxDeduction,
    Subsidiary,
    Vendor,
} from '../models/order-entry-state_v2';
import { orderEntryV2ActionString as v2 } from './action-types';

export const setV2Products = createAction(v2.SET_V2_PRODUCTS, props<{ products: Partial<Product>[] }>());
export const setV2ProductLineItemId = createAction(
    v2.SET_V2_PRODUCT_LINE_ITEM_ID,
    props<{ productIdx?: number; lineItemId: string }>(),
);
export const setV2ProductFilled = createAction(
    v2.SET_V2_PRODUCT_FILLED,
    props<{ productIdx?: number; isFilled: boolean }>(),
);
export const setV2ProductsWinBack = createAction(
    v2.SET_V2_PRODUCTS_WIN_BACK,
    props<{
        winBackProducts: (Pick<Product, 'podPdr' | 'powerOrGas' | 'deliveryAddress' | 'assetIntegrationId'> & {
            technicalDetails: Pick<Product['technicalDetails'], 'switchOutDate'>;
        })[];
    }>(),
);

export const setV2ProductByIdx = createAction(
    v2.SET_V2_PRODUCT_BY_IDX,
    props<{ productIdx: number; product: DeepPartial<Product> }>(),
);

export const setV2ProductInsurance = createAction(
    v2.SET_V2_PRODUCT_INSURANCE,
    props<{
        insuranceProducts: Pick<Product, 'podPdr' | 'powerOrGas' | 'deliveryAddress' | 'isCommodityActive'>[];
    }>(),
);
export const setV2MainAddress = createAction(v2.SET_V2_MAIN_ADDRESS, props<{ address: Address }>());
export const setV2CustomerCode = createAction(v2.SET_V2_CUSTOMER_CODE, props<{ customerCode: string }>());
export const setV2CommunicationAddress = createAction(
    v2.SET_V2_COMMUNICATION_ADDRESS,
    props<{ address: Address; notFromAddress?: boolean; productIdx?: number[] }>(),
);
export const setV2DomicileAddress = createAction(v2.SET_V2_DOMICILE_ADDRESS, props<{ address: Address }>());
export const setV2SubsidiaryAddress = createAction(
    v2.SET_V2_SUBSIDIARY_ADDRESS,
    props<{ subsidiary: Subsidiary; productIdx: number }>(),
);
export const setV2InvoiceShippingMethod = createAction(
    v2.SET_V2_INVOICE_SHIPPING_METHOD,
    props<{ method: AptBillType }>(),
);
export const setV2OrderEntryState = createAction(v2.SET_V2_ORDER_ENTRY_STATE, props<{ state: OrderEntryState_v2 }>());
export const setV2SkipPrices = createAction(v2.SET_V2_SKIP_COSTS, props<{ skipPrices: boolean }>());
export const setV2Prices = createAction(v2.SET_V2_PRICES, props<{ prices: ProductPriceEntity[] }>());
export const setV2EffectiveDate = createAction(
    v2.SET_V2_EFFECTIVE_DATE,
    props<{ effectiveDate: Date; immediateEffect?: boolean }>(),
);
export const setV2EffectiveDateComplex = createAction(
    v2.SET_V2_EFFECTIVE_DATE_COMPLEX,
    props<{ products: number[]; immediateEffect?: boolean }>(),
);

export const setV2Convention = createAction(v2.SET_V2_CONVENTION, props<{ convention: Convention }>());
export const setV2TaxDeduction = createAction(v2.SET_V2_TAX_DEDUCTION, props<{ taxDeduction: TaxDeduction }>());
export const setV2CreditAssignment = createAction(v2.SET_V2_CREDIT_ASSIGNMENT, props<{ creditAssignment: boolean }>());
export const setV2ProductTaxDeduction = createAction(
    v2.SET_V2_PRODUCT_TAX_DEDUCTION,
    props<{ productTaxDeduction: ProductTaxDeduction }>(),
);
export const setV2PartNumber = createAction(v2.SET_V2_PART_NUMBER, props<{ partNumber: string }>());
export const setV2Vendor = createAction(v2.SET_V2_VENDOR, props<{ vendor: Vendor }>());
export const setV2Pdf = createAction(v2.SET_V2_PDF, props<{ pdf: string }>());
export const setV2BusinessDetails = createAction(
    v2.SET_V2_BUSINESS_DETAILS,
    props<{ businessDetails: BusinessDetails }>(),
);
export const setV2CartId = createAction(v2.SET_V2_CART_ID, props<{ id: string }>());
export const setV2TypeOfUsage = createAction(v2.SET_V2_TYPE_OF_USAGE, props<{ typeOfUsage: TypeOfUse }>());
export const setV2TechnicalDetailsPower = createAction(
    v2.SET_V2_TECHNICAL_DETAILS_POWER,
    props<{ technicalDetails: Partial<PowerTechnicalDetails> }>(),
);
export const setV2TechnicalDetailsGas = createAction(
    v2.SET_V2_TECHNICAL_DETAILS_GAS,
    props<{ technicalDetails: Partial<GasTechnicalDetails> }>(),
);
export const setV2SendCommunications = createAction(
    v2.SET_V2_SEND_COMMUNICATIONS,
    props<{ sendCommunications: boolean }>(),
);
export const setV2DeliveryAddress = createAction(
    v2.SET_V2_DELIVERY_ADDRESS,
    props<{ supplyAddress?: Address; shippingAddress?: Address }>(),
);
export const setV2Ese = createAction(v2.SET_V2_ESE, props<{ ese?: Ese; lineItemId: string }>());

export const setV2SupplyUse = createAction(v2.SET_V2_SUPPLY_USE, props<{ supplyUse: SupplyUse }>());

export const setV2FatturazioneElettronica = createAction(
    v2.SET_V2_ELECTRONIC_INVOICING,
    props<{ fatturazioneElettronica: FatturazioneElettronica }>(),
);
export const setV2RevocationReason = createAction(
    v2.SET_V2_REVOCATION_REASON,
    props<{ revocationReason: MotivoRevoca }>(),
);
export const setV2AnagraficaMB = createAction(v2.SET_V2_ANAGRAFICA_MB, props<{ anagraficaMb: DatiAnagraficiMBV2 }>());
export const setV2InformationCosts = createAction(v2.SET_V2_INFORMATION_COSTS, props<{ informationCosts: boolean }>());
export const setV2SetEmailConfirmed = createAction(v2.SET_V2_EMAIL_CONFERMED, props<{ emailConfirmed: boolean }>());
export const setV2CustomerIsRegistered = createAction(
    v2.SET_V2_CUSTOMER_REGISTERED,
    props<{ isRegistered: boolean }>(),
);
export const setV2RemoteReadingStatus = createAction(v2.SET_V2_REMOTE_READING_STATUS, props<{ status: boolean }>());
export const setV2TechnicalAppointmentDate = createAction(
    v2.SET_V2_TECHNICAL_APPOINTMENT_DATE,
    props<{ booking: ExtracommodityBooking }>(),
);
//TODO Lorenzo - Si può rendere più elegante?
export const setV2ShippingAddressOnAllSmartHome = createAction(
    v2.SET_V2_SHIPPING_ADDRESS_ALL_SMARTHOME,
    props<{ deliveryAddress: Address }>(),
);

export const setV2IsInstantAcceptance = createAction(
    v2.SET_V2_IS_INSTANT_ACCEPTANCE,
    props<{ isInstantAcceptance: boolean }>(),
);
export const setV2BlockDataEditingOnResendDoiCheckDoi = createAction(
    v2.SET_V2_BLOCK_DATA_EDITING_ON_DOI_CHECKDOI_RESEND,
    props<{ blockDataEditingOnDoiCheckDoiResend: boolean }>(),
);

export const setV2DatiMultiPayment = createAction(
    v2.SET_V2_DATI_MULTI_PAYMENT,
    props<{ datiPagamento: FormDatiPagamento; productIdx: number[] }>(),
);

export const setV2CheckAutofill = createAction(
    v2.SET_V2_CHECK_AUTOFILL,
    props<{ checkAutofill: string; productId: string }>(),
);

export const setV2TransferModel = createAction(
    v2.SET_V2_TRANSFER_MODEL,
    props<{ transferModel: TransferModelResponse }>(),
);
